/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import GlobalStyle from '../components/global';
import SEO from '../components/seo';
import PropTypes from 'prop-types';

/*
 * Smooth scrooling Link tags in Gatsby
 *
 * https://github.com/chrisfitkin/gatsby-smooth-scroll-demo
 */
if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
}

const Layout = ({ title, children, url }) => {
    return (
        <>
            <GlobalStyle />
            <SEO title={title} url={url} />
            <main>{children}</main>
        </>
    );
};

export default Layout;

Layout.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    url: PropTypes.string
};

Layout.defaultProps = {
    url: null
};
