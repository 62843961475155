import { createGlobalStyle } from 'styled-components';
import spaceMono from '../assets/fonts/SpaceMono-Regular.ttf';
import spaceMonoBold from '../assets/fonts/SpaceMono-Bold.ttf';
import openSans from '../assets/fonts/OpenSans-Regular.ttf';
import openSansSemiBold from '../assets/fonts/OpenSans-SemiBold.ttf';
import openSansExtraBold from '../assets/fonts/OpenSans-ExtraBold.ttf';

const TypoGraphy = createGlobalStyle`

    @font-face {
        font-family: SpaceMono;
        src: url(${spaceMono}) format('truetype');
        font-style: normal;
        font-weight: normal;
    }

    @font-face {
        font-family: SpaceMono;
        src: url(${spaceMonoBold}) format('truetype');
        font-style: normal;
        font-weight: bold;
    }

    @font-face {
        font-family: OpenSans;
        src: url(${openSans}) format('truetype');
        font-style: normal;
        font-weight: normal;
    }

    @font-face {
        font-family: OpenSans;
        src: url(${openSansSemiBold}) format('truetype');
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: OpenSans;
        src: url(${openSansExtraBold}) format('truetype');
        font-style: normal;
        font-weight: 800;
    }

    body { 
        font-family: 'SpaceMono', sans-serif; 
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 58px;
        line-height: 1.2em;
        font-weight: 700;
    }

    @media (min-width: 0) and (max-width: 1024px) {
        h1 { font-size: 48px; }
    }

    @media (min-width: 0) and (max-width: 768px) {
        h1 { font-size: 40px; }
    }

    h2 {
        font-size: 50px;
        line-height: 1.2em;
    }

    @media (min-width: 0) and (max-width: 1024px) {
        h2 { font-size: 34px; }
    }

    h3 {
        font-size: 48px;
        line-height: 1.2em;
        font-weight: 700;
    }

    @media (min-width: 0) and (max-width: 1024px) {
        h3 { font-size: 34px; }
    }

    h4 {
        font-size: 36px;
        line-height: 1.2em;
    }

    @media (min-width: 0) and (max-width: 768px) {
        h4 { font-size: 28px; }
    }

    h5 {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        line-height: 1.4em;
        font-weight: 400;
    }

    @media (min-width: 0) and (max-width: 1024px) {
        h5 { font-size: 16px; }
    }
`;

export default TypoGraphy;
