import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import TypoGraphy from './typography.jsx';

const Reset = createGlobalStyle`
    ${normalize}

    * {
        box-sizing: border-box;
    }

    html {
        height: 100%;
    }

    body {
        margin: 0;
        min-height: 100%;
        background-color: #fff;
        font-family: Arial, sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #333;
    }

    img {
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
    }
`;

const GlobalStyle = () => (
    <>
        <Reset />
        <TypoGraphy />
    </>
);

export default GlobalStyle;
