import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '657273734744380';

const options = {
    autoConfig: true,
    debug: false
};

export function init() {
    ReactPixel.init(PIXEL_ID, {}, options);
    ReactPixel.pageView();
}

export function convert() {
    ReactPixel.track('Purchase', { value: 0.0, currency: 'EUR' });
}
