const GOOGLE_TAG_ID = 'AW-736753488';
const AW_LABEL = 'yxtmCIG06dMBENDup98C';


export async function init(analytics) {
    function loadError(error) {
        throw new URIError(`The script ${error.target.src} didn't load correctly`);
    }

    const gtm = document.createElement('script');
    gtm.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`;
    gtm.onerror = loadError;

    document.head.appendChild(gtm);
    fire('js', new Date());
}

export function convert() {
    fire('event', 'conversion', {
        send_to: `${GOOGLE_TAG_ID}/${AW_LABEL}`
    });
}

export function fire(...args) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
}
